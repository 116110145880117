import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import B1 from '../../assets/baners/banner1.gif'
import B2 from '../../assets/baners/banner2.webp'
import B3 from '../../assets/baners/banner3.webp'
import B4 from '../../assets/baners/banner4.webp'
import B5 from '../../assets/baners/banner5.webp'
import B6 from '../../assets/baners/banner6.webp'

const BannerSlide = () => {
  return (
    <Swiper
      spaceBetween={0}
      slidesPerView={1}
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
      }}
      modules={[Autoplay]}
      className="mt-2"
    >
      <SwiperSlide><img src={B1} alt="" className="w-full" /></SwiperSlide>
      <SwiperSlide><img src={B2} alt="" className="w-full" /></SwiperSlide>
      <SwiperSlide><img src={B3} alt="" className="w-full" /></SwiperSlide>
      <SwiperSlide><img src={B4} alt="" className="w-full" /></SwiperSlide>
      <SwiperSlide><img src={B5} alt="" className="w-full" /></SwiperSlide>
      <SwiperSlide><img src={B6} alt="" className="w-full" /></SwiperSlide>
    </Swiper>
  )
}

export default BannerSlide